import { useCallback, useEffect, useMemo, useState } from 'react';
import uuid from 'uuid/v4';

import type { EditorActions } from '@atlaskit/editor-core';
import type { ADFEntity } from '@atlaskit/adf-utils/types';

import { fg } from '@confluence/feature-gating';

import type { Card, LinkCardsParameters } from '../../link-cards/linkCardsTypes';
import {
	ImagePosition,
	RenderVariant,
	SegmentationTargets,
	Orientation,
	Alignment,
} from '../../link-cards/linkCardsTypes';
import { checkCards } from '../../link-cards/LinkCardsContent';
import { useCustomSitesExtensions } from '../useCustomSitesExtensions';
import { linkCardsExtensionType } from '../../link-cards/linkCardsExtensionType';
import { CAROUSEL_VERSION_1 } from '../../carousel/carouselVersion';
import {
	CARDS_VERSION_1,
	CARDS_VERSION_2,
	CARDS_VERSION_3,
} from '../../link-cards/linkCardsVersion';

import type {
	ExtensionActions as LinkCardsActions,
	UpdateField,
	UseExtensionParametersAndActionsProps,
	UseExtensionParametersAndActionsResponse,
} from './configPanelTypes';
import { CARD_FIELDS } from './configPanelTypes';
import type { CustomSitesExtensionTypes } from './ConfigPanelContent';

export const initialUnsplashImage: Partial<Card> = {
	imageSrc:
		'https://images.unsplash.com/photo-1579547621706-1a9c79d5c9f1?auto=format&fit=crop&q=80&w=1080',
	imageAltText: 'purple and pink light illustration',
};

export const createNewCardWithDefaultParameters = (defaultField?: UpdateField<Card>): Card => ({
	cardId: uuid(),
	link: '',
	isLinkUserEdited: false,
	title: '',
	isTitleUserEdited: false,
	description: '',
	isDescriptionUserEdited: false,
	imagePosition: ImagePosition.MIDDLE,
	...initialUnsplashImage,
	...defaultField,
});

export const updateLinkAndConfluencePageId = (
	link: string,
	confluencePageId?: string,
	isLinkUserEdited?: boolean,
	linkResourceType?: string,
) => {
	return {
		[CARD_FIELDS.LINK]: link,
		[CARD_FIELDS.CONFLUENCE_PAGE_ID]: confluencePageId || '',
		[CARD_FIELDS.IS_LINK_USER_EDITED]: isLinkUserEdited || false,
		[CARD_FIELDS.LINK_RESOURCE_TYPE]: linkResourceType || '',
	};
};

export const updateImageSrcAndImageAltText = (imageSrc: string, imageAltText: string) => {
	return {
		[CARD_FIELDS.IMAGE_SRC]: imageSrc,
		[CARD_FIELDS.IMAGE_ALT_TEXT]: imageAltText,
	};
};

export const updateNodeParameters = (editorActions: EditorActions, updatedParameters: any) => {
	const selectedNodeJSON = editorActions.getSelectedNode()?.toJSON();

	const updatedNodeSelection = {
		...selectedNodeJSON,
		attrs: {
			...selectedNodeJSON?.attrs,
			parameters: { ...updatedParameters },
		},
	};

	editorActions.replaceSelection(updatedNodeSelection, true);
};

export const replaceSelectedNode = (editorActions: EditorActions, nodeAdf: ADFEntity) => {
	editorActions.replaceSelection(nodeAdf, true);
};

const fetchLocalIdMacroParameters = (
	editorActions: EditorActions,
	localId: string,
	extensionType: CustomSitesExtensionTypes,
	isCardsConfigUpdatesEnabled: boolean,
): LinkCardsParameters => {
	const localIdNode = editorActions?.getNodeByLocalId?.(localId)?.toJSON();
	let params = localIdNode?.attrs?.parameters;

	if (params === undefined) {
		return undefined as any;
	}

	// Make a copy of the params object to avoid mutating the original object
	params = { ...params };

	// If the extension was created prior to removal of type field, remove it from object here
	// This update is/was not done in combination with a feature gate
	delete params['type'];

	params = updateParamsToVersion1(extensionType, params);
	params = updateParamsToVersion2(extensionType, params);
	params = updateParamsToVersion3(extensionType, isCardsConfigUpdatesEnabled, params);

	return params;
};

const updateParamsToVersion1 = (
	extensionType: CustomSitesExtensionTypes,
	params: LinkCardsParameters,
): LinkCardsParameters => {
	if (extensionType === linkCardsExtensionType) {
		params.version = CARDS_VERSION_1;
	} else {
		params.version = CAROUSEL_VERSION_1;
	}
	return params;
};

const updateParamsToVersion2 = (
	extensionType: CustomSitesExtensionTypes,
	params: LinkCardsParameters,
): LinkCardsParameters => {
	// Update version to 2 and add renderVariant field
	// Only set for cards extension
	if (extensionType === linkCardsExtensionType) {
		// Always set version to 2
		params.version = CARDS_VERSION_2;
		// Set render variant to manual only if it is not set
		params.renderVariant ??= RenderVariant.MANUAL;
	}
	return params;
};

const updateParamsToVersion3 = (
	extensionType: CustomSitesExtensionTypes,
	isCardsConfigUpdatesEnabled: boolean,
	params: LinkCardsParameters,
): LinkCardsParameters => {
	// Update version to 3 and add orientation field
	// Only set for cards extension
	if (extensionType === linkCardsExtensionType && isCardsConfigUpdatesEnabled) {
		// Always set version to 3
		params.version = CARDS_VERSION_3;
		// Set orientation to stack only if it is not set
		params.orientation ??= Orientation.STACKED;
		// Set alignment to center only if it is not set
		params.alignment ??= Alignment.CENTER;
	}
	return params;
};

const removeProperties = (
	extensionsPropertiesToRemove: string[],
	extensionParameters: LinkCardsParameters,
): LinkCardsParameters => {
	const mutatedParameters = { ...extensionParameters };

	extensionsPropertiesToRemove.forEach((field) => {
		delete mutatedParameters[field];
	});

	return mutatedParameters;
};

const updateCardInList = (cards: Card[], cardId: string, updatedField: UpdateField<Card>): Card[] =>
	cards.map((card) => {
		if (card.cardId === cardId) {
			return { ...card, ...updatedField };
		}
		return card;
	});

// TODO Refactor name to be Carousel/Cards specific
export const useExtensionParametersAndActions = ({
	localId,
	editorActions,
	extensionType,
}: UseExtensionParametersAndActionsProps): UseExtensionParametersAndActionsResponse => {
	const [extensionParameters, setExtensionParameters] = useState<LinkCardsParameters>(
		fetchLocalIdMacroParameters(
			editorActions,
			localId,
			extensionType,
			fg('company_hub_cards_config_updates'),
		),
	);
	const [_, { setIsInAutoFocusMode, setSelectedToEditCardId }] = useCustomSitesExtensions();
	const { cards, segmentationTarget, isSegmentationShown } = extensionParameters ?? {};

	useEffect(() => {
		const extensionNodeParams = fetchLocalIdMacroParameters(
			editorActions,
			localId,
			extensionType,
			fg('company_hub_cards_config_updates'),
		);
		setExtensionParameters(extensionNodeParams);
	}, [editorActions, localId, extensionType]);

	const updateExtensionParams = useCallback(
		(
			newExtensionParameters: UpdateField<LinkCardsParameters>,
			options: { fieldsToRemove?: string[] } = {},
		) => {
			setExtensionParameters((prevParams) => {
				let mutatedFields = { ...prevParams };
				const fieldsToRemove = [...(options?.fieldsToRemove || [])];

				if (fieldsToRemove.length > 0) {
					mutatedFields = removeProperties(fieldsToRemove, prevParams);
				}

				const updatedParams = {
					...mutatedFields,
					...newExtensionParameters,
				} as LinkCardsParameters;

				// Save params to ADF and state
				updateNodeParameters(editorActions, updatedParams);

				return updatedParams;
			});
		},
		[editorActions],
	);

	const addCard = useCallback(() => {
		const newCard = createNewCardWithDefaultParameters({
			[CARD_FIELDS.AUDIENCE_SEGMENTATION_GROUP_IDS]:
				isSegmentationShown && segmentationTarget === SegmentationTargets.ALL
					? cards[0]?.audienceSegmentationGroupIds
					: undefined,
		});

		updateExtensionParams({
			cards: [...(checkCards(cards) || []), newCard],
		});
		setIsInAutoFocusMode(true);
		setSelectedToEditCardId(newCard.cardId);
	}, [
		cards,
		segmentationTarget,
		isSegmentationShown,
		updateExtensionParams,
		setSelectedToEditCardId,
		setIsInAutoFocusMode,
	]);

	const removeCard = useCallback(
		(cardId: string) =>
			updateExtensionParams({
				cards: cards.filter((card) => card.cardId !== cardId),
			}),
		[cards, updateExtensionParams],
	);

	const updateMainFormField = useCallback(
		(updatedFields: UpdateField<LinkCardsParameters>, options = {}) =>
			updateExtensionParams(updatedFields, options),
		[updateExtensionParams],
	);

	const updateCardFormField = useCallback(
		(cardId: string, updatedField: UpdateField<Card>) => {
			const updatedCards = updateCardInList(cards, cardId, updatedField);

			updateExtensionParams({ cards: updatedCards });
		},
		[cards, updateExtensionParams],
	);

	const updateCardFormFieldForAllCards = useCallback(
		(updatedField: UpdateField<Card>) => {
			const updatedCards = cards.map((card) => {
				return { ...card, ...updatedField };
			});

			updateExtensionParams({ cards: updatedCards });
		},
		[cards, updateExtensionParams],
	);

	const extensionActions: LinkCardsActions = useMemo(
		() => ({
			addCard,
			removeCard,
			updateMainFormField,
			updateCardFormField,
			updateCardFormFieldForAllCards,
		}),
		[addCard, removeCard, updateMainFormField, updateCardFormField, updateCardFormFieldForAllCards],
	);

	return { extensionParameters, extensionActions };
};
