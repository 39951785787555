import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css, cssMap } from '@compiled/react';

import {
	Card as SmartCard,
	MediaPlacement,
	PreviewBlock,
	SnippetBlock,
	SmartLinkSize,
	SmartLinkTheme,
	TitleBlock,
	CustomBlock,
} from '@atlaskit/smart-card';
import { token } from '@atlaskit/tokens';

import { markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';

import { getImageTypeToShow } from '../../../shared-components/CustomImageUploadRenderer/getImageTypeToShow';
import { CustomImageUploadRenderer } from '../../../shared-components/CustomImageUploadRenderer/CustomImageUploadRenderer';
import { CardSizes } from '../../linkCardsTypes';
import type { OnCardFailed, OnCardSucceeded } from '../useCardsCombinedExperienceState';

import {
	EmptyAndSmallCardWrapper as EmptyCardWrapper,
	CommonCardWrapperStyles,
} from './cardComponentsStyles';
import { useSmartCardFallbackUrl } from './useSmartCardFallbackUrl';

type LinkCardsContentProps = {
	contentId: string;
	cardId: string;
	title?: string;
	imageSrc?: string;
	description?: string;
	size: CardSizes;
	cardHeight: number;
	isInViewMode: boolean;
	onCardSucceeded: OnCardSucceeded;
	onCardFailed: OnCardFailed;
	imageAltText?: string;
};

const i18n = defineMessages({
	emptyStatePlaceholderTitle: {
		id: 'custom-sites-extensions.link-cards.smart-card.empty-state.placeholder-title',
		defaultMessage: 'Add a title',
		description: 'Placeholder title that is displayed in Smart Card when no title is provided',
	},
});

const titleBlockStyles = css({
	marginTop: token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		fontWeight: token('font.weight.medium'),
		flexShrink: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:visited, a:focus, a:hover, a:active': {
		textDecoration: 'none',
		pointerEvents: 'none',
	},
});

const titleBlockMarginTopStyles = cssMap({
	small: {
		marginTop: token('space.0'),
	},
	other: {
		marginTop: token('space.100'),
	},
});

const titleBlockLinkTextColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		color: token('color.text'),
	},
});

const titleBlockLinkTextSubtlestColorStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		color: token('color.text.subtlest'),
	},
});

const previewStyles = css({
	flexGrow: 1,
});

const customImageUploadStyles = cssMap({
	left: {
		position: 'absolute',
		width: '72px',
		top: token('space.0'),
		bottom: token('space.0'),
		left: token('space.0'),
	},
	right: {
		marginLeft: 'calc(var(--container-gap-left) * -1)',
		marginRight: 'calc(var(--container-gap-right) * -1)',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:first-of-type': {
			marginTop: 'calc(var(--container-padding) * -1)',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:last-of-type': {
			marginBottom: 'calc(var(--container-padding) * -1)',
		},
	},
});

export const EmptyCard = ({
	contentId,
	cardId,
	size,
	title,
	description,
	imageSrc,
	cardHeight,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	imageAltText,
}: LinkCardsContentProps) => {
	const intl = useIntl();

	const handleResolve = useCallback(() => {
		onCardSucceeded(cardId);
	}, [onCardSucceeded, cardId]);

	const handleError = useCallback(
		(error: any) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.COMPANY_HUB,
			});
			markErrorAsHandled(error);
			onCardFailed(error);
		},
		[onCardFailed],
	);

	const url = useSmartCardFallbackUrl();

	const { showUnsplashImage, showCustomUploadedImage, hasImage } = getImageTypeToShow({
		imageSrc,
	});

	const getSmartCardSize = useCallback(() => {
		switch (size) {
			case CardSizes.SMALL:
			case CardSizes.MEDIUM:
				return SmartLinkSize.Medium;
			case CardSizes.LARGE:
			default:
				return SmartLinkSize.Large;
		}
	}, [size]);

	return (
		<CommonCardWrapperStyles isInViewMode={isInViewMode} isEmptyCard>
			<EmptyCardWrapper
				size={size}
				data-testid="empty-state-visual-smart-card"
				cardHeight={cardHeight}
				isEmptyCard
				hasImage={hasImage}
			>
				<SmartCard
					id={cardId}
					onError={handleError}
					onResolve={handleResolve}
					appearance="inline"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: false,
						size: getSmartCardSize(),
					}}
					url={url}
				>
					{showUnsplashImage && (
						<PreviewBlock
							ignoreContainerPadding
							placement={size === CardSizes.SMALL ? MediaPlacement.Left : undefined}
							css={previewStyles}
							overrideUrl={imageSrc}
						/>
					)}
					{showCustomUploadedImage && (
						<CustomBlock
							css={[
								size === CardSizes.SMALL
									? customImageUploadStyles.left
									: customImageUploadStyles.right,
							]}
						>
							<CustomImageUploadRenderer
								contentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
								shouldShowErrorMessage={size !== CardSizes.SMALL}
							/>
						</CustomBlock>
					)}
					<TitleBlock
						text={title || intl.formatMessage(i18n.emptyStatePlaceholderTitle)}
						css={[
							titleBlockStyles,
							title ? titleBlockLinkTextColorStyles : titleBlockLinkTextSubtlestColorStyles,
							size === CardSizes.SMALL
								? titleBlockMarginTopStyles.small
								: titleBlockMarginTopStyles.other,
						]}
						hideIcon
						anchorTarget="_self"
					/>
					{size !== CardSizes.SMALL && <SnippetBlock text={description} />}
				</SmartCard>
			</EmptyCardWrapper>
		</CommonCardWrapperStyles>
	);
};
