import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import throttle from 'lodash/throttle';

import { IconButton } from '@atlaskit/button/new';
import { cssMap, cx } from '@atlaskit/css';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import { Box, Inline, Stack } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';

import type { CardSizes, Alignment } from '../linkCardsTypes';
import { useOrientationScrolling } from '../../shared-components/useOrientationScrolling';

import { CardOrientationWrapper } from './CardOrientationWrapper';
import { useCardWidthAndSpacingCalculations } from './useCardWidthAndSpacingCalculations';

const i18n = defineMessages({
	previous: {
		id: 'custom-sites-extensions.link-cards.row-orientation.previous',
		defaultMessage: 'Previous',
		description:
			'Label text for the button that, when clicked, goes back one card and displays the previous card',
	},
	next: {
		id: 'custom-sites-extensions.link-cards.row-orientation.next',
		defaultMessage: 'Next',
		description:
			'Label text for the button that, when clicked, goes forward one card and displays the next card',
	},
});

export const MIN_GAP_BETWEEN_CARDS = 16;
export const MAX_GAP_BETWEEN_CARDS = 24;

export type CardsOrientationProps = {
	numberOfCards: number;
	cardsSize: CardSizes;
	alignment: Alignment;
	isStacked: boolean;
	isInViewMode: boolean;
	children: React.ReactElement[];
};

const styles = cssMap({
	flexBox: {
		display: 'flex',
		position: 'relative',
		paddingLeft: token('space.0'),
	},
	stacked: {
		flexWrap: 'wrap',
	},
	row: {
		overflow: 'hidden',
	},
	left: {
		justifyContent: 'flex-start',
	},
	center: {
		justifyContent: 'center',
	},
	right: {
		justifyContent: 'flex-end',
	},
});

export const CardsOrientation = ({
	numberOfCards,
	cardsSize,
	alignment,
	isStacked,
	isInViewMode,
	children,
}: CardsOrientationProps) => {
	const { calculations, setCurrentCardsGridWidth, setCurrentCardWidth } =
		useCardWidthAndSpacingCalculations({
			numberOfCards,
			cardsSize,
		});

	const {
		cardWidthOnFullRow,
		isFirstRowFull,
		isGridSmallerOrEqualWidthToCards,
		gaps,
		maxNumberOfCardsPerRow,
	} = calculations;

	const throttledSetWidth = throttle(setCurrentCardsGridWidth, 200);
	const orientation = isStacked ? 'grid' : 'row';
	const spaceBetweenCards = gaps.spaceBetweenCards;

	const {
		isAtBeginning,
		isAtEnd,
		scrollToPrevious,
		scrollToNext,
		scrollToIndex,
		requestScrollIndex,
		resetScrollIndex,
	} = useOrientationScrolling({
		maxNumberOfItemsPerRow: maxNumberOfCardsPerRow,
		numberOfItems: children.length,
		isRow: !isStacked,
	});

	const allCardsShown = isInViewMode && isAtBeginning && isAtEnd;

	return (
		<Stack space="space.075">
			<Box
				testId={`link-cards-content-cards-${orientation}`}
				xcss={cx(
					styles.flexBox,
					isStacked ? styles.stacked : styles.row,
					/**
					 * Align left regardless of alignment if
					 * - orientation is row
					 * - the grid width is smaller or equal to the minimum width of the card size
					 */
					!isStacked || isGridSmallerOrEqualWidthToCards ? styles.left : styles[alignment],
				)}
				style={{
					gap: `${spaceBetweenCards}px`,
				}}
			>
				{!!children &&
					children.map((child, index) => (
						<CardOrientationWrapper
							key={index}
							isFirstRowFull={isFirstRowFull}
							cardWidthOnFullRow={cardWidthOnFullRow}
							cardSize={cardsSize}
							setWidth={index === 0 ? setCurrentCardWidth : undefined}
							scrollToCard={index === scrollToIndex}
							clearScrollToCard={resetScrollIndex}
							testId={`link-cards-content-cards-${orientation}-card-${index}`}
							index={index}
							requestScrollIndex={requestScrollIndex}
						>
							{child}
						</CardOrientationWrapper>
					))}
				<WidthObserver setWidth={throttledSetWidth} offscreen />
			</Box>
			{!isStacked && !allCardsShown && (
				<Inline
					space="space.050"
					alignInline="end"
					testId="link-cards-content-cards-row-navigation"
				>
					<IconButton
						shape="circle"
						icon={ArrowLeftIcon}
						isDisabled={isAtBeginning}
						label={<FormattedMessage {...i18n.previous} />}
						onClick={scrollToPrevious}
					/>
					<IconButton
						shape="circle"
						icon={ArrowRightIcon}
						isDisabled={isAtEnd}
						label={<FormattedMessage {...i18n.next} />}
						onClick={scrollToNext}
					/>
				</Inline>
			)}
		</Stack>
	);
};
