import { useState, useEffect, useCallback } from 'react';

export type OrientationScrollProps = {
	maxNumberOfItemsPerRow: number;
	numberOfItems: number;
	isRow: boolean;
};

type OrientationScrollControls = {
	isAtBeginning: boolean;
	isAtEnd: boolean;
	scrollToPrevious: () => void;
	scrollToNext: () => void;
	scrollToIndex: number;
	resetScrollIndex: () => void;
	requestScrollIndex: (index: number) => void;
};

// This hook tracks a "window" of items being shown at any given point.
// The number of items allowed in that window may change, as well as the
// actual number of items in the list. To the left and right of this window
// there may be more items, which can be "viewed" by sliding the window
// left and right (updating the indices). see `scrollToPrevious`, `scrollToNext`.
//
// `scrollToIndex` indicates to the consumer which particular item from the list
// should be scrolled into view to visually reflect this window change. This is
// needed because depending on whether the window is sliding to the left or right,
// items that are within the new window may already be in the viewport and invoking
// scroll on them won't do anything.
// `resetScrollIndex` is for clearing it once the item has been scrolled to.
//
// The hook also tells us whether the window is at the beginning or end of the list
// so the consumer can prevent attempts to scroll beyond those limits.
export const useOrientationScrolling = ({
	maxNumberOfItemsPerRow,
	numberOfItems,
	isRow,
}: OrientationScrollProps): OrientationScrollControls => {
	// Indices represents the leftmost item and rightmost item currently in view.
	const [window, setWindow] = useState<[number, number]>([0, 0]);

	// Used for scrolling to a particular item. Set to -1 by default as we haven't scrolled yet.
	const [scrollToIndex, setScrollToIndex] = useState<number>(-1);

	// Used to keep track of which item is currently being edited in the config panel to adjust window accordingly.
	const [requestedScrollIndex, setRequestedScrollIndex] = useState<number>(-1);

	useEffect(() => {
		if (typeof maxNumberOfItemsPerRow !== undefined) {
			// Viewable window indexes are inclusive of left and right index, so we need to decrement by one unless maxNumberOfItemsPerRow is 0.
			// Examples:
			// Cards: 1 2 3 4 5, Card indexes: 0 1 2 3 4
			// maxNumberOfItemsPerRow is 0, current left index is at card 1 (ie. index 0) --> right index should be at card 1 (index 0), 1 card in view. Calculation: 0 + 0 - 0 = 0
			// maxNumberOfItemsPerRow is 2, current left index is at card 1 (ie. index 0) --> right index should be at card 2 (index 1), 2 cards in view. Calculation: 0 + 2 - 1 = 1
			// maxNumberOfItemsPerRow is 3, current left index is at card 2 (ie. index 1) --> right index should be at card 4 (index 3), 3 cards in view. Calculation: 1 + 3 - 1 = 3
			const extraDecrement = maxNumberOfItemsPerRow === 0 ? 0 : 1;
			const newRightIndex = window[0] + maxNumberOfItemsPerRow - extraDecrement;

			setWindow([window[0], newRightIndex]);
		}

		if (requestedScrollIndex >= 0) {
			// Trigger scroll unconditionally, this works for grid view as well (which doesn't use the window logic)
			setScrollToIndex(requestedScrollIndex);

			// Only update the window in row orientation, as we don't use a window for grid view.
			if (isRow) {
				if (requestedScrollIndex !== window[0] && requestedScrollIndex !== window[1]) {
					// This means we've scrolled somewhere left beyond the existing visible window, so we update the window to the left.
					if (requestedScrollIndex < window[0]) {
						setWindow([requestedScrollIndex, requestedScrollIndex + maxNumberOfItemsPerRow - 1]);
					}
					// This means we've scrolled somewhere right beyond the existing visible window, so we update the window to the right.
					else if (requestedScrollIndex > window[1]) {
						setWindow([requestedScrollIndex - maxNumberOfItemsPerRow + 1, requestedScrollIndex]);
					}
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maxNumberOfItemsPerRow, requestedScrollIndex, isRow]);

	const scrollToPrevious = useCallback(() => {
		const newLeftIndex = window[0] - 1;
		const newRightIndex = window[1] - 1;
		setWindow([newLeftIndex, newRightIndex]);
		setScrollToIndex(newLeftIndex);
	}, [window]);

	const scrollToNext = () => {
		const newLeftIndex = window[0] + 1;
		const newRightIndex = window[1] + 1;
		setWindow([newLeftIndex, newRightIndex]);
		setScrollToIndex(newRightIndex);
	};

	useEffect(() => {
		// If user deletes an item near the end of the list, our window of
		// items being shown may need recalculating (if right index is too high)
		if (window[1] > numberOfItems - 1 && window[0] > 0) {
			scrollToPrevious();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [numberOfItems, scrollToPrevious]);

	const noScrollNeeded = numberOfItems <= maxNumberOfItemsPerRow;

	const isAtBeginning = noScrollNeeded || window[0] === 0;
	const isAtEnd = noScrollNeeded || window[1] === numberOfItems - 1;

	const resetScrollIndex = () => {
		setScrollToIndex(-1);
	};

	return {
		isAtBeginning,
		isAtEnd,
		scrollToPrevious,
		scrollToNext,
		scrollToIndex,
		requestScrollIndex: setRequestedScrollIndex,
		resetScrollIndex,
	};
};
