import React, { useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { css } from '@compiled/react';

import {
	CustomBlock,
	ElementName,
	MetadataBlock,
	PreviewBlock,
	SmartLinkSize,
	SmartLinkTheme,
	SnippetBlock,
	TitleBlock,
} from '@atlaskit/smart-card';
import { CardSSR as SmartCardSSR } from '@atlaskit/smart-card/ssr';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';

import { CardSizes } from '../../linkCardsTypes';
import { useSmartCardEmoji } from '../useSmartCardEmoji';
import { useTrackedSmartCardState } from '../useTrackedSmartCardState';
import { EmojiRenderer } from '../../../shared-components';
import { getImageTypeToShow } from '../../../shared-components/CustomImageUploadRenderer/getImageTypeToShow';
import { CustomImageUploadRenderer } from '../../../shared-components/CustomImageUploadRenderer/CustomImageUploadRenderer';

import {
	CommonCardWrapperStyles,
	MediumLargeCardWrapper as CardWrapper,
} from './cardComponentsStyles';
import { CardErrorState } from './ErrorStates';
import type { CommonCardProps } from './commonCardProps';

const titleBlockUnauthorizedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		display: 'none',
	},
	// ensure "Connect your account" link for unauth 3p links is boosted
	// over general smart card link destination so user can connect account
	zIndex: 1,
});

const titleBlockHideUnauthorizedStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[data-smart-element-group] > div': {
		// Need to override display block !important styles applied in cardComponentsStyles
		// TODO, let's get rid of those in rearchitecture.
		display: 'none',
	},
});

const titleBlockStyles = css({
	marginTop: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-smart-element-link]': {
		fontWeight: token('font.weight.medium'),
		color: token('color.text'),
		flexShrink: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'a, a:visited, a:focus, a:hover, a:active': {
		textDecoration: 'none',
		pointerEvents: 'auto',
	},
});

const customImageUploadStyles = css({
	marginLeft: 'calc(var(--container-gap-left) * -1)',
	marginRight: 'calc(var(--container-gap-right) * -1)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-of-type': {
		marginTop: 'calc(var(--container-padding) * -1)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-of-type': {
		marginBottom: 'calc(var(--container-padding) * -1)',
	},
});

const floatingEmojiStyles = css({
	position: 'relative',
	top: token('space.negative.150'),
	bottom: token('space.025'),
	height: '0px',
	overflow: 'visible',
});

export const MediumCard: FC<CommonCardProps> = ({
	contentId,
	cardId,
	link,
	title,
	imageSrc,
	cardHeight,
	isAvatarShown,
	isPublishDateShown,
	description,
	isInViewMode,
	onCardSucceeded,
	onCardFailed,
	imageAltText,
	createAnalyticsEvent,
	...analyticsPassThroughProps
}) => {
	const {
		errorCardStatus,
		isNoResult,
		isRestricted,
		isUnauthorized,
		trackAndCategorizeError,
		trackSuccess,
	} = useTrackedSmartCardState(cardId, link, onCardSucceeded, onCardFailed);

	const onResolve = useCallback(() => {
		trackSuccess();
	}, [trackSuccess]);

	const { emojiId } = useSmartCardEmoji(link);
	const { showUnsplashImage, showExternalSiteImage, showCustomUploadedImage, hasImage } =
		getImageTypeToShow({
			imageSrc,
		});

	useEffect(() => {
		onResolve();
	}, [onResolve]);

	const showCustomFieldsUnauthed =
		isUnauthorized && fg('company_hub_unauth_links_custom_title_fix');
	const showCustomTitleUnauthed = showCustomFieldsUnauthed && !!title;
	const showCustomDescUnauthed = showCustomFieldsUnauthed && !!description;

	if (isRestricted || isNoResult)
		return (
			<CardErrorState
				isRestricted={isRestricted}
				isNoResult={isNoResult}
				link={link}
				cardId={cardId}
				cardSize={CardSizes.MEDIUM}
				cardHeight={cardHeight}
				isInViewMode={isInViewMode}
				errorStatus={errorCardStatus}
				createAnalyticsEvent={createAnalyticsEvent}
				{...analyticsPassThroughProps}
			/>
		);

	return (
		<CommonCardWrapperStyles isInViewMode={isInViewMode}>
			<CardWrapper cardHeight={cardHeight} hasImage={hasImage}>
				<SmartCardSSR
					id={cardId}
					appearance="inline"
					frameStyle="show"
					platform="web"
					ui={{
						theme: SmartLinkTheme.Black,
						clickableContainer: isInViewMode,
						size: SmartLinkSize.Medium,
					}}
					url={link}
					onResolve={onResolve}
					onError={trackAndCategorizeError}
				>
					{(showUnsplashImage || showExternalSiteImage) && (
						<PreviewBlock ignoreContainerPadding overrideUrl={imageSrc} />
					)}
					{showCustomUploadedImage && (
						<CustomBlock css={customImageUploadStyles}>
							<CustomImageUploadRenderer
								contentId={contentId}
								imageSrc={imageSrc}
								imageAltText={imageAltText}
								shouldShowErrorMessage
							/>
						</CustomBlock>
					)}
					<CustomBlock css={floatingEmojiStyles} testId="smart-block-link-card-emoji-container">
						<EmojiRenderer emojiId={emojiId} />
					</CustomBlock>
					<TitleBlock
						hideIcon
						metadata={!!isAvatarShown ? [{ name: ElementName.OwnedByGroup }] : []}
						text={title}
						anchorTarget="_self"
						css={[
							titleBlockStyles,
							showCustomTitleUnauthed && titleBlockHideUnauthorizedStyles,
							!showCustomTitleUnauthed && isUnauthorized && titleBlockUnauthorizedStyles,
						]}
					/>
					{!!isPublishDateShown && <MetadataBlock primary={[{ name: ElementName.ModifiedOn }]} />}
					{(!isUnauthorized || showCustomDescUnauthed) && <SnippetBlock text={description} />}
				</SmartCardSSR>
			</CardWrapper>
		</CommonCardWrapperStyles>
	);
};
