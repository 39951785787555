import type { FC } from 'react';
import React, { useContext } from 'react';
import times from 'lodash/times';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	EDITOR,
	MacroExperienceFailure,
	MacroExperienceSuccess,
	RENDERER,
} from '@confluence/macro-tracker';
import { AudienceSegmentationContext } from '@confluence/custom-sites-extensions/entry-points/AudienceSegmentationContext';
import { fg } from '@confluence/feature-gating';
import { ConfluenceSmartCardProvider } from '@confluence/smart-card-provider/entry-points/ConfluenceSmartCardProvider';

import type { LinkCardsParameters, Card } from '../linkCardsTypes';
import { CardSizes, Orientation, Alignment, RenderVariant } from '../linkCardsTypes';
import { linkCardsExtensionType } from '../linkCardsExtensionType';
import { CardSelectedToEditWrapper } from '../../shared-components/CardSelectedToEditWrapper';

import {
	EmptyCard,
	ExtraSmallCard,
	HeroCard,
	LargeCard,
	MediumCard,
	SmallCard,
	SmallPlaceholderCard,
	MediumPlaceholderCard,
	LargePlaceholderCard,
	ZeroResultsCard,
	LoadingCardsError,
} from './CardComponents';
import { CardsOrientation } from './CardsOrientation';
import { cardsDimensions } from './CardOrientationWrapper';
import { useCardsCombinedExperienceState } from './useCardsCombinedExperienceState';
import { useValidateRequiredParameters } from './useValidateRequiredParameters';
import { useCards } from './useCards';

export type LinkCardsContentProps = {
	extensionLocalId: string;
	parameters: LinkCardsParameters;
	isInViewMode?: boolean;
	contentId: string;
	experienceName: string;
};

const CARD_SIZE_MAP = {
	[CardSizes.EXTRA_SMALL]: ExtraSmallCard,
	[CardSizes.SMALL]: SmallCard,
	[CardSizes.MEDIUM]: MediumCard,
	[CardSizes.LARGE]: LargeCard,
	[CardSizes.HERO]: HeroCard,
};

const getCardComponent = (cardSize: CardSizes) => {
	return CARD_SIZE_MAP[cardSize] ?? MediumCard;
};

const validateCardParams = (
	currentParameters: LinkCardsParameters,
	isCardsConfigUpdatesEnabled: boolean,
) => {
	const { orientation, alignment } = currentParameters;

	return {
		...currentParameters,
		// if alignment is not available (cards created before alignment), default to center
		alignment: alignment && isCardsConfigUpdatesEnabled ? alignment : Alignment.CENTER,
		// if orientation is not available (cards created before orientation), default to stacked
		orientation:
			orientation === Orientation.ROW && isCardsConfigUpdatesEnabled
				? Orientation.ROW
				: Orientation.STACKED,
	};
};

export const LinkCardsContent: FC<LinkCardsContentProps> = ({
	extensionLocalId,
	parameters,
	isInViewMode,
	contentId,
	experienceName,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const validatedParameters = useValidateRequiredParameters(parameters);
	const validatedCardParams = validateCardParams(
		validatedParameters,
		fg('company_hub_cards_config_updates'),
	);
	const { renderVariant, size } = validatedParameters;

	const isDynamicCards = renderVariant === RenderVariant.DYNAMIC;
	const cardHeight = cardsDimensions[size].height;
	const CardComponent = getCardComponent(size);

	return isDynamicCards ? (
		<DynamicCardsContent
			extensionLocalId={extensionLocalId}
			parameters={validatedCardParams}
			isInViewMode={isInViewMode}
			contentId={contentId}
			experienceName={experienceName}
			createAnalyticsEvent={createAnalyticsEvent}
			cardHeight={cardHeight}
			CardComponent={CardComponent}
		/>
	) : (
		<ManualCardsContent
			extensionLocalId={extensionLocalId}
			parameters={validatedCardParams}
			isInViewMode={isInViewMode}
			contentId={contentId}
			experienceName={experienceName}
			createAnalyticsEvent={createAnalyticsEvent}
			cardHeight={cardHeight}
			CardComponent={CardComponent}
		/>
	);
};

const ManualCardsContent = ({
	extensionLocalId,
	parameters,
	isInViewMode,
	contentId,
	experienceName,
	createAnalyticsEvent,
	cardHeight,
	CardComponent,
}) => {
	const {
		size,
		isAvatarShown,
		isPublishDateShown,
		cards,
		isSegmentationShown,
		orientation,
		alignment,
	} = parameters;
	const { areAllCardsLoaded, cardFailureError, onCardSucceeded, onCardFailed } =
		useCardsCombinedExperienceState(cards);
	const { isTargetAudience } = useContext(AudienceSegmentationContext);

	// filter out cards for segmentation, but if company hub audience segmentation is disabled or if card segmentation is disabled, no filtering
	const filteredCards =
		!fg('company_hub_audience_segmentation') || !isSegmentationShown
			? cards
			: cards.filter((card) => isTargetAudience(card.audienceSegmentationGroupIds));

	return (
		<ConfluenceSmartCardProvider>
			<CardsOrientation
				numberOfCards={filteredCards.length}
				cardsSize={size}
				isStacked={orientation === Orientation.STACKED}
				isInViewMode={isInViewMode}
				alignment={alignment}
			>
				{filteredCards.map((card) =>
					!!card.link ? (
						<CardSelectedToEditWrapper
							key={card.cardId}
							cardId={card.cardId}
							extensionLocalId={extensionLocalId}
						>
							<CardComponent
								contentId={contentId}
								cardHeight={cardHeight}
								isAvatarShown={isAvatarShown}
								isPublishDateShown={isPublishDateShown}
								isInViewMode={!!isInViewMode}
								onCardSucceeded={onCardSucceeded}
								onCardFailed={onCardFailed}
								extensionType={linkCardsExtensionType}
								analyticsSource="cardsExtensionConfig"
								createAnalyticsEvent={createAnalyticsEvent}
								{...card}
							/>
						</CardSelectedToEditWrapper>
					) : (
						<CardSelectedToEditWrapper
							key={card.cardId}
							cardId={card.cardId}
							extensionLocalId={extensionLocalId}
						>
							<EmptyCard
								contentId={contentId}
								size={size}
								cardHeight={cardHeight}
								isInViewMode={!!isInViewMode}
								onCardSucceeded={onCardSucceeded}
								onCardFailed={onCardFailed}
								{...card}
							/>
						</CardSelectedToEditWrapper>
					),
				)}
			</CardsOrientation>

			{areAllCardsLoaded && (
				<MacroExperienceSuccess
					name={experienceName}
					mode={isInViewMode ? RENDERER : EDITOR}
					contentId={contentId}
				/>
			)}
			{!!cardFailureError && (
				<MacroExperienceFailure
					name={experienceName}
					contentId={contentId}
					mode={isInViewMode ? RENDERER : EDITOR}
					error={cardFailureError}
					attributes={{}}
					source="LinkCardsContent"
				/>
			)}
		</ConfluenceSmartCardProvider>
	);
};

const PLACEHOLDER_CARD_SIZE_MAP = {
	[CardSizes.SMALL]: SmallPlaceholderCard,
	[CardSizes.MEDIUM]: MediumPlaceholderCard,
	[CardSizes.LARGE]: LargePlaceholderCard,
};

const getPlaceholderCardComponent = (cardSize: CardSizes) => {
	return PLACEHOLDER_CARD_SIZE_MAP[cardSize] ?? MediumPlaceholderCard;
};

const getDynamicCardsContentCards = ({
	parameters,
	createAnalyticsEvent,
	cardHeight,
	CardComponent,
	extensionLocalId,
	onCardSucceeded,
	onCardFailed,
	contentId,
	isInViewMode,
	cards,
	loading,
}: {
	parameters: LinkCardsParameters;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	cardHeight: number;
	CardComponent: FC<any>;
	extensionLocalId: string;
	onCardSucceeded: (cardId: string) => void;
	onCardFailed: (error: Error) => void;
	contentId: string;
	isInViewMode?: boolean;
	cards: Card[];
	loading: boolean;
}) => {
	const { size, isAvatarShown, isPublishDateShown, filters } = parameters;
	const PlaceholderCardComponent = getPlaceholderCardComponent(size);

	if (!filters) {
		return times(3, (index) => <ZeroResultsCard key={index} cardSize={size} />);
	}

	if (!cards.length || loading) {
		return times(3, (index) => <PlaceholderCardComponent key={index} cardHeight={cardHeight} />);
	}

	return cards.map((card: Card, index: number) => {
		if (!card.link) {
			return <ZeroResultsCard key={index} cardSize={size} />;
		}

		return (
			<CardSelectedToEditWrapper
				key={index}
				cardId={card.cardId}
				extensionLocalId={extensionLocalId}
			>
				<CardComponent
					contentId={contentId}
					cardHeight={cardHeight}
					isAvatarShown={isAvatarShown}
					isPublishDateShown={isPublishDateShown}
					isInViewMode={!!isInViewMode}
					onCardSucceeded={onCardSucceeded}
					onCardFailed={onCardFailed}
					extensionType={linkCardsExtensionType}
					analyticsSource="cardsExtensionConfig"
					createAnalyticsEvent={createAnalyticsEvent}
					{...card}
				/>
			</CardSelectedToEditWrapper>
		);
	});
};

const DynamicCardsContent = ({
	extensionLocalId,
	parameters,
	isInViewMode,
	contentId,
	experienceName,
	createAnalyticsEvent,
	cardHeight,
	CardComponent,
}) => {
	const { size, orientation, alignment } = parameters;
	const { cards, loading, error } = useCards(parameters);
	const { areAllCardsLoaded, cardFailureError, onCardSucceeded, onCardFailed } =
		useCardsCombinedExperienceState(cards);

	return (
		<ConfluenceSmartCardProvider>
			<CardsOrientation
				numberOfCards={cards.length}
				cardsSize={size}
				isStacked={orientation === Orientation.STACKED}
				isInViewMode={isInViewMode}
				alignment={alignment}
			>
				{getDynamicCardsContentCards({
					parameters,
					createAnalyticsEvent,
					cardHeight,
					CardComponent,
					extensionLocalId,
					onCardSucceeded,
					onCardFailed,
					contentId,
					isInViewMode,
					cards,
					loading,
				})}
			</CardsOrientation>

			{error && <LoadingCardsError error={error} />}

			{areAllCardsLoaded && (
				<MacroExperienceSuccess
					name={experienceName}
					mode={isInViewMode ? RENDERER : EDITOR}
					contentId={contentId}
				/>
			)}
			{!!cardFailureError && (
				<MacroExperienceFailure
					name={experienceName}
					contentId={contentId}
					mode={isInViewMode ? RENDERER : EDITOR}
					error={cardFailureError}
					attributes={{}}
					source="LinkCardsContent"
				/>
			)}
		</ConfluenceSmartCardProvider>
	);
};
